import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import uzTranslations from 'translations/uzTranslations.json';

i18n
  .use(initReactI18next) 
  .init({
    resources: {
      uz: { translation: uzTranslations } 
    },
    lng: "uz", 
    fallbackLng: "uz", 
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;