import { useState, useEffect } from 'react';

const useFetchAllCourses = () => {
    const [allCourses, setAllCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    const fetchAllCourses = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_CONTENT_API_BASE_URL}/courses`);
            if (!response.ok) throw new Error('Failed to fetch all courses');
            
            const data = await response.json();
            setAllCourses(data.courses);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setError(error.message);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchAllCourses();
    }, []);

    return { allCourses, loading, error };
};

export default useFetchAllCourses;