import React, { useEffect, useState } from 'react';
import { useSessionStorage } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { ArrowLeft } from 'react-bootstrap-icons';
import clickLogo from 'assets/images/payment/click.png';
import paymeLogo from 'assets/images/payment/payme.png';
import { displayPrice } from 'helper/priceHelpers';
import useLogin from 'hooks/useLogin';
import usePayment from 'hooks/usePayment';

const PAYMENT_METHODS = {
  CLICK: 'CLICK_UZ',
  PAYME: 'PAYME_UZ',
};

const isValidPaymentMethod = (method) => Object.values(PAYMENT_METHODS).includes(method);

const PaymentPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedPlan] = useSessionStorage('selectedPlan');
  const [selectedMethod, setSelectedMethod] = useState('');
  const { user } = useLogin();
  const { initiatePayment, loading, error } = usePayment(); 

  useEffect(() => {
    if (!selectedPlan) {
      navigate('/pricing');
    }
  }, [selectedPlan, navigate]);

  const handleConfirm = async () => {
    if (!isValidPaymentMethod(selectedMethod)) {
      alert(t('alert_select_payment_method'));
      return;
    }

    const paymentData = {
      userId: user?.id,
      planId: selectedPlan?.id,
      durationDays: selectedPlan?.duration,
      paymentMethod: selectedMethod,
      successUrl: 'https://example.com/success',
    };

    try {
      const data = await initiatePayment(paymentData);

      if (data?.sessionURL) {
        window.location.href = data.sessionURL;
      } else {
        alert(t('error_creating_payment_session'));
      }
    } catch (err) {
      alert(err || t('error_creating_payment_session'));
    }
  };

  return (
    <Container className="my-5 mt-15">
      <div className="d-flex align-items-center mb-5" style={{ cursor: 'pointer' }} onClick={() => navigate('/pricing')}>
        <div
          className="bg-light-primary rounded-circle d-flex align-items-center justify-content-center me-3"
          style={{ width: '36px', height: '36px' }}
        >
          <ArrowLeft className="text-primary" size={18} />
        </div>
        <h4 className="mb-0 fw-semibold fs-5">{t('select_pricing_plan')}</h4>
      </div>

      <Row className="justify-content-center">
        <Col md={6}>
          <Card>
            <Card.Body className="p-5">
              <h3 className="fw-bold">{t('select_payment_method')}</h3>
              <div className="d-flex align-items-center mb-4">
                <Form.Check
                  type="radio"
                  name="payment"
                  id="payme"
                  checked={selectedMethod === PAYMENT_METHODS.PAYME}
                  onChange={() => setSelectedMethod(PAYMENT_METHODS.PAYME)}
                />
                <div
                  onClick={() => setSelectedMethod(PAYMENT_METHODS.PAYME)}
                  style={{ cursor: 'pointer', marginRight: '20px' }}
                >
                  <img src={paymeLogo} alt="Payme" style={{ height: '130px', width: '130px' }} />
                </div>

                <Form.Check
                  type="radio"
                  name="payment"
                  id="click"
                  checked={selectedMethod === PAYMENT_METHODS.CLICK}
                  onChange={() => setSelectedMethod(PAYMENT_METHODS.CLICK)}
                />
                <div
                  onClick={() => setSelectedMethod(PAYMENT_METHODS.CLICK)}
                  style={{ cursor: 'pointer', marginRight: '20px' }}
                >
                  <img src={clickLogo} alt="Click" style={{ height: '130px', width: '130px' }} />
                </div>
              </div>

              <Button
                onClick={handleConfirm}
                className="btn btn-primary w-30"
                disabled={!selectedMethod || loading} 
              >
                {loading ? t('loading') : t('confirm')}
              </Button>
              {error && <div className="text-danger mt-2">{error}</div>}
            </Card.Body>
          </Card>
        </Col>

        <Col md={4}>
          <Card className="shadow-sm border border-primary">
            <Card.Body className="p-5">
              <h3 className="fw-bold">{t('order_summary')}</h3>
              <div className="d-flex justify-content-between mt-4">
                <span className="fw-semibold">{t('user_information')}</span>
              </div>
              <div className="d-flex justify-content-between mb-3">
                <span>{t('email')}:</span>
                <span>{user?.email}</span>
              </div>
              <hr />
              <div className="d-flex justify-content-between mb-3">
                <p className="fw-semibold">{t('plan')}</p>
                <p className="fw-semibold">{t('price')}</p>
              </div>
              <div className="d-flex justify-content-between">
                <p>{selectedPlan?.name}</p>
                <p>{displayPrice(selectedPlan?.price?.amount)} {selectedPlan?.price?.currency}</p>
              </div>
            </Card.Body>
            <div className="bg-light-primary rounded-bottom p-3">
              <div className="d-flex justify-content-between">
                <strong>{t('total_amount')}</strong>
                <strong>{displayPrice(selectedPlan?.price?.amount)} {selectedPlan?.price?.currency}</strong>
              </div>
            </div>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PaymentPage;