import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SimpleBar from 'simplebar-react';
import GKAccordionDefault from 'components/common/accordions/GKAccordionDefault';

const Sidebar = ({ courseIndex, courseId, lessonId, completedLessons, hasActiveSubscription }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleReturnToCourse = () => {
    navigate(`/courses/${courseId}`);
  };

  return (
    <div className="d-none d-md-block position-relative" style={{ width: '300px' }}>
      <div style={{ position: 'sticky', top: '20px' }}>
        <SimpleBar style={{ maxHeight: '80vh' }}>
          <button 
            className="btn btn-link text-primary fs-5 mb-3 p-0" 
            onClick={handleReturnToCourse}
            style={{ textDecoration: 'none' }}
          >
            &larr; {t('return_to_course')}
          </button>
          <h4 className="mb-3">{t('contents')}</h4>
          <GKAccordionDefault 
            accordionItems={courseIndex}
            courseId={courseId}
            currentLessonId={lessonId}
            completedLessons={completedLessons} 
            hasActiveSubscription={hasActiveSubscription}
          />
        </SimpleBar>
      </div>
    </div>
  );
};

export default Sidebar;