import { Route, Routes } from 'react-router-dom';
import 'assets/scss/theme.scss';

import DefaultLayout from 'components/layouts/DefaultLayout';
import CoursePage from 'pages/CoursePage';
import LessonPage from 'pages/LessonPage';
import StudentPage from 'pages/StudentPage';
import StudentSubscriptions from 'components/account-settings/Subscriptions';
import EditProfile from 'components/account-settings/EditProfile';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import useLogin from 'hooks/useLogin';
import Dashboard from 'pages/StudentDashboard';
import PricingPage from 'pages/PricingPage';
import PaymentPage from 'pages/PaymentPage';

const ProtectedRoute = ({ component, ...args }) => {
    const Component = withAuthenticationRequired(component, args);
    return <Component />;
};

const AllRoutes = () => {
    const { isAuthenticated } = useLogin();

    return (
        <Routes>
            <Route element={<DefaultLayout />}>
                <Route
                    path="/"
                    element={<Dashboard />}
                />
                <Route
                    path="/courses/:courseId"
                    element={<CoursePage />}
                />
                <Route
                    path="/pricing"
                    element={<PricingPage />}
                />
                <Route
                    path="/payment"
                    element={<ProtectedRoute component={PaymentPage} />}
                />                 
                <Route
                    exact path="/courses/:courseId/:lessonId"
                    element={<ProtectedRoute component={LessonPage} />}
                />
                <Route
                    path="/student/page/"
                    element={<StudentPage />}
                />
                <Route
                    path="/student/student-subscriptions/"
                    element={<StudentSubscriptions />}
                />
                <Route
                    path="/student/student-edit-profile/"
                    element={<EditProfile />}
                />
            </Route>
        </Routes>
    );
};

export default AllRoutes;