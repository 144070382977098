import ReactGA from 'react-ga4';

export const initializeAnalytics = () => {
  const measurementId = process.env.REACT_APP_GA_MEASUREMENT_ID;
  if (measurementId) {
    ReactGA.initialize(measurementId);
  }
};

export const trackPageView = (path) => {
  if (path) {
    ReactGA.send({ hitType: 'pageview', page: path });
  }
};