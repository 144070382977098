import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Col, Row, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import useLogin from 'hooks/useLogin';
import useAuthTokens from 'hooks/useAuthTokens';
import useCourseIndex from 'hooks/useCourseIndex';
import useLessonContent from 'hooks/useLessonContent';
import useLessonCompletion from 'hooks/useLessonCompletion';
import useActiveSubscription from 'hooks/useActiveSubscription'; 
import Content from 'components/lesson-page/Content';
import Navigation from 'components/lesson-page/Navigation';
import Sidebar from 'components/lesson-page/Sidebar'; 
import { setupCourseIfNotExists } from 'services/courseService';

const LessonPage = () => {
  const { courseId, lessonId } = useParams();
  const { t } = useTranslation();
  const { user } = useLogin();
  const { accessToken } = useAuthTokens();
  const navigate = useNavigate();
  
  const { courseIndex, error: indexError, loading: indexLoading } = useCourseIndex(`${process.env.REACT_APP_CONTENT_API_BASE_URL}/courses/${courseId}/lessons`);
  const { lessonContent, contentError, loading: contentLoading } = useLessonContent(courseId, lessonId);
  const [completedLessons, setCompletedLessons] = useState([]);
  
  const { isLessonCompleted, toggleCompletion } = useLessonCompletion(lessonId, courseId, user, setCompletedLessons);
  const hasActiveSubscription = useActiveSubscription(user ? user.id : null); 

  useEffect(() => {
    if (user) {
      setupCourseIfNotExists(user.id, accessToken, courseId);
    }
  }, [courseId, user]);

  const { sections, currentLessonIndex, nextLesson, prevLesson } = useMemo(() => {
    const sections = Array.isArray(courseIndex) ? courseIndex : [];
    const lessonsArray = sections.flatMap(section => section.topics || []);
    const currentLessonIndex = lessonsArray.findIndex(lesson => lesson.id === lessonId);
    const nextLesson = currentLessonIndex < lessonsArray.length - 1 ? lessonsArray[currentLessonIndex + 1] : null;
    const prevLesson = currentLessonIndex > 0 ? lessonsArray[currentLessonIndex - 1] : null;
    return { sections, currentLessonIndex, nextLesson, prevLesson };
  }, [courseIndex, lessonId]);

  const handleNavigation = (lesson) => {
    if (lesson) navigate(`/courses/${courseId}/${lesson.id}`);
  };

  if (indexLoading || contentLoading || !user) return <div>{t('loading')}</div>;
  if (indexError || !courseIndex) return <div>{t('error_loading_course')}</div>;

  return (
    <Fragment>
      <Container fluid className="mt-5">
        <Row>
          <Col xs={12} md={3}
            className="d-none d-md-block position-fixed"
            style={{
              zIndex: 1000
            }}
          >
            <Sidebar 
              courseIndex={courseIndex} 
              courseId={courseId} 
              lessonId={lessonId} 
              completedLessons={completedLessons}
              hasActiveSubscription={hasActiveSubscription} 
            />
          </Col>

          <Col
            xs={12} 
            md={{ span: 8, offset: 3 }} 
            className="p-5"  
            style={{ maxWidth: '100%' }}
          >
            <Content lessonContent={lessonContent} contentError={contentError} />
            <Navigation 
              prevLesson={prevLesson} 
              nextLesson={nextLesson} 
              isLessonCompleted={isLessonCompleted} 
              toggleCompletion={toggleCompletion} 
              handlePrevLesson={() => handleNavigation(prevLesson)} 
              handleNextLesson={() => handleNavigation(nextLesson)} 
              prevLabel={t('previous_lesson')}
              nextLabel={t('next_lesson')}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default LessonPage;