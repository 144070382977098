export const DashboardMenu = [
	{
		id: 1,
		title: 'My Subscriptions',
		link: '/student/student-subscriptions/',
		icon: 'home'
	}
];

export const AccountSettingsMenu = [
	{
		id: 1,
		title: 'Edit Profile',
		link: '/student/student-edit-profile/',
		icon: 'settings'
	},
	{
		id: 2,
		title: 'Sign Out',
		link: '/',
		icon: 'power'
	}
];

export const InstructorDashboardMenu = [DashboardMenu, AccountSettingsMenu];

export default InstructorDashboardMenu;
