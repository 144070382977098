import { useState, useEffect } from 'react';
import useAuthTokens from 'hooks/useAuthTokens';

const useActiveSubscription = (userId) => {
  const { accessToken } = useAuthTokens();
  const [hasActiveSubscription, setHasActiveSubscription] = useState(false);

  useEffect(() => {
    const checkSubscription = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/subscription/api/v1/subscriptions/${userId}/active`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch subscription data');
        }

        const data = await response.json();
        const now = new Date();
        const hasActive = data.some((sub) => new Date(sub.expiresAt) > now);
        setHasActiveSubscription(hasActive);
      } catch (error) {
        console.error('Failed to fetch subscription:', error);
        setHasActiveSubscription(false);
      }
    };

    if (userId) {
      checkSubscription();
    }
  }, [userId, accessToken]);

  return hasActiveSubscription;
};

export default useActiveSubscription;