import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const useAuthTokens = () => {
  const { isAuthenticated, getIdTokenClaims, getAccessTokenSilently } = useAuth0();
  const [tokens, setTokens] = useState({ idToken:null, accessToken: null });

  useEffect(() => {
    const fetchTokens = async () => {
      if (isAuthenticated) {
        try {
          const idToken = (await getIdTokenClaims()).__raw
          const accessToken = await getAccessTokenSilently();
          
          setTokens({ idToken, accessToken });
        } catch (error) {
          console.error('Error fetching tokens:', error);
        }
      } else {
        setTokens({ idToken:null, accessToken: null }); // Reset tokens if not authenticated
      }
    };

    fetchTokens();
  }, [isAuthenticated, getIdTokenClaims, getAccessTokenSilently]);
  return tokens; // Return the tokens
};

export default useAuthTokens;
