const checkCourseExists = async (userId, accessToken, courseId) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/api/v1/users/${userId}/courses/${courseId}`,
      {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
        },
      }
    );
    if (response.status === 404) {
      return false;
    }
    if (!response.ok) {
      console.error(`Unexpected response status: ${response.status}`);
      return false;
    }
    return true; 
  } catch (error) {
    console.error("Error checking course existence:", error);
    return false;
  }
};

const initializeCourseProgress = async (userId, accessToken, courseId) => {
  try {
    await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/api/v1/users/${userId}/courses/${courseId}/lessons/completed`, {
      method: 'POST',
      headers: { 
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`
      },
      body: JSON.stringify([]),
    });
  } catch (error) {
    console.error('Error initializing course progress:', error);
  }
};

export const setupCourseIfNotExists = async (userId, accessToken, courseId) => {
  const courseExists = await checkCourseExists(userId, accessToken, courseId);
  if (!courseExists) {
    await initializeCourseProgress(userId, accessToken, courseId);
  }
};