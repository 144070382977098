import { useEffect, useState } from 'react';
import useAuthTokens from './useAuthTokens';
import { useAuth0 } from '@auth0/auth0-react';

const useLogin = () => {
    const { idToken } = useAuthTokens();
    const { loginWithRedirect, isAuthenticated, logout: auth0Logout } = useAuth0();
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (idToken) {
            fetch(`${process.env.REACT_APP_API_BASE_URL}/users/api/v1/users/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${idToken}`
                },
            })
                .then((response) => response.json())
                .then((data) => {
                    setUser({ id: data.id, email: data.email });
                })
                .catch((error) => {
                    console.error('Error logging in:', error);
                });
        }
    }, [idToken]);

    const initiateLogin = (options) => {
        loginWithRedirect({
            ...options,
            authorizationParams: {
                audience: process.env.REACT_APP_API_BASE_URL,
            },
        });
    } 

    const logout = (redirectUrl) => {
        auth0Logout({
            openUrl(_) {
                window.location.replace(redirectUrl);
            }
        });
        setUser(null);
    }

    return { user, initiateLogin, logout, isAuthenticated };
};

export default useLogin;