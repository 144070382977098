import React, { Fragment, useState } from 'react';
import { Container, Row, Col, Form, InputGroup } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CourseListInProgress from 'components/student-dashboard/CourseListInProgress';
import ProfileCover from 'components/common/headers/ProfileCover';
import useLogin from 'hooks/useLogin';
import CourseNotInProgressList from 'components/student-dashboard/CourseNotInProgressList';
import CourseList from 'components/student-dashboard/CourseList';

const Dashboard = () => {
    const { t } = useTranslation();
    const { user } = useLogin();
    const isLoggedIn = !!user;
    const [searchQuery, setSearchQuery] = useState("");

    return (
        <Fragment>
            <section className="pt-5 pb-5">
                <Container>
                    {isLoggedIn && (
                        <Row className="mt-0 mt-md-4 mb-4">
                            <Col lg={12} md={12} sm={12}>
                                <ProfileCover user={user} />
                            </Col>
                        </Row>
                    )}

                    <Row className="mt-8 pb-5 justify-content-center">
                        <Col lg={6} md={8} sm={10}>
                            <InputGroup>
                                <InputGroup.Text>
                                    <i className="bi bi-search text-primary"></i>
                                </InputGroup.Text>
                                <Form.Control
                                    type="text"
                                    placeholder={t('search_courses')}
                                    value={searchQuery}
                                    onChange={(e) => setSearchQuery(e.target.value)}
                                />
                                {searchQuery && (
                                    <InputGroup.Text
                                        onClick={() => setSearchQuery('')}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        &#x2715;
                                    </InputGroup.Text>
                                )}
                            </InputGroup>
                        </Col>
                    </Row>
                </Container>
            </section>

            {isLoggedIn && (
                <>
                    <section className="mb-8">
                        <Container>
                            <CourseListInProgress 
                                userId={user?.id} 
                                searchQuery={searchQuery} 
                                showprogressbar={true} 
                            /> 
                        </Container>
                    </section>

                    <section className="mb-8">
                        <Container>
                            <CourseNotInProgressList 
                                userId={user?.id} 
                                searchQuery={searchQuery} 
                            />
                        </Container>
                    </section>
                </>
            )}

            {!isLoggedIn && (
                <section>
                    <Container>
                        <CourseList searchQuery={searchQuery} />
                    </Container>
                </section>
            )}
        </Fragment>
    );
};

export default Dashboard;