import { useState, useEffect } from 'react';
import useAuthTokens from './useAuthTokens';


const useLessonCompletion = (lessonId, courseId, user, setCompletedLessons) => {
  const [isLessonCompleted, setIsLessonCompleted] = useState(false);
  const { accessToken } = useAuthTokens();
  useEffect(() => {
    const fetchCompletedLessons = async () => {
      if (!user) {
        return;
      }

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/api/v1/users/${user.id}/courses/${courseId}`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            },
          }
        );
        if (!response.ok) {
          return;
        }

        const data = await response.json();
        const completedLessonIds = data.completedLessons || [];
        setCompletedLessons(completedLessonIds); 
        setIsLessonCompleted(completedLessonIds.includes(lessonId)); 
      } catch (error) {
        console.error('Error fetching lesson completion:', error.message);
      }
    };

    fetchCompletedLessons();
  }, [lessonId, courseId, user, setCompletedLessons]);

  const toggleCompletion = async (checked) => {
    if (!user) {
      return;
    }

    setIsLessonCompleted(checked);
    const lessonArray = [lessonId];

    try {
      if (checked) {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/api/v1/users/${user.id}/courses/${courseId}/lessons/completed`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(lessonArray),
        });
        if (!response.ok) {
          throw new Error('Failed to mark lesson as complete');
        }
        setCompletedLessons((prev) => [...prev, lessonId]);
      } else {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/api/v1/users/${user.id}/courses/${courseId}/lessons/completed`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(lessonArray),
        });
        if (!response.ok) {
          throw new Error('Failed to mark lesson as incomplete');
        }
        setCompletedLessons((prev) => prev.filter((id) => id !== lessonId));
      }
    } catch (error) {
      console.error('Error toggling lesson completion:', error.message);
    }
  };

  return { isLessonCompleted, toggleCompletion };
};

export default useLessonCompletion;