import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { displayPrice } from 'helper/priceHelpers';
import { useSessionStorage } from 'react-use';

const PlanCard = ({ plan, durationLabel, durationDays }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [, setSessionPlan] = useSessionStorage('selectedPlan'); 

  const handleBuyClick = () => {
    const selectedPlan = {
      ...plan,
      duration: durationDays,
    };

    setSessionPlan(selectedPlan);
    setTimeout(() => {
      navigate('/payment'); 
    }, 0); // we want navigate to be after setting the session plan in the event queue 
  };

  return (
    <div className="card border-light shadow-sm p-1 mb-4 bg-white" style={{ width: '30rem' }}>
      <div className="card-body text-center">
        <h5 className="card-title mb-3" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
          {plan.name}
        </h5>
        <p className="text-muted mb-4" style={{ fontSize: '0.9rem' }}>
          {plan.description}
        </p>
        <h2 className="fw-bold mb-4">
          {displayPrice(plan.price?.amount)} 
          <small className="text-muted fs-6">
            {plan.price?.currency} / {durationLabel}
          </small>
        </h2>
        <button
          className="btn btn-outline-primary w-100 mb-4"
          onClick={handleBuyClick}
        >
          {t('buy_plan')}
        </button>
        <div className="text-start">
          <h6 className="fw-bold">{t('plan_includes')}</h6>
          <ul className="list-unstyled">
            {(plan.features || []).map((feature, index) => (
              <li key={index} className="d-flex align-items-center mb-2">
                <span className="text-primary me-1">&#10003;</span>
                {feature}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;