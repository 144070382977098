import { useState, useEffect } from 'react';

const useLessonContent = (courseId, lessonId) => {
  const [lessonContent, setLessonContent] = useState('');
  const [contentError, setContentError] = useState(null);

  useEffect(() => {
    if (!lessonId) return;

    const controller = new AbortController();

    const fetchLessonContent = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_CONTENT_API_BASE_URL}/courses/${courseId}/lessons/${lessonId}/content`, {
          signal: controller.signal,
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.text();
        setLessonContent(data);
        setContentError(null);
      } catch (error) {
        if (error.name !== 'AbortError') {
          setContentError(error.message);
        }
      }
    };

    fetchLessonContent();

    return () => {
      controller.abort();
    };
  }, [lessonId]);

  return { lessonContent, contentError };
};

export default useLessonContent;