import { BrowserRouter as Router, useNavigate, useLocation } from 'react-router-dom';
import { Auth0Provider } from '@auth0/auth0-react';
import ScrollToTop from 'components/layouts/ScrollToTop';
import AllRoutes from 'routes/AllRoutes';
import { useEffect } from 'react';
import { initializeAnalytics, trackPageView } from './utils/analytics'; 
import './i18n';

// import required stylesheet
import 'simplebar/dist/simplebar.min.css';
import 'tippy.js/animations/scale.css';

const Auth0ProviderWithRedirectCallback = ({ children, ...props }) => {
  const navigate = useNavigate();
  const onRedirectCallback = (appState) => {
    navigate((appState && appState.returnTo) || window.location.pathname);
  };
  return (
    <Auth0Provider onRedirectCallback={onRedirectCallback} {...props}>
      {children}
    </Auth0Provider>
  );
};

const PageTracker = () => {
  const location = useLocation();

  useEffect(() => {
    trackPageView(location.pathname + location.search); 
  }, [location]);

  return null;
};

function App() {
  useEffect(() => {
    initializeAnalytics(); 
  }, []);

  return (
    <Router>
      <Auth0ProviderWithRedirectCallback
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_API_BASE_URL
        }}
      >
        <ScrollToTop />
        <PageTracker /> 
        <AllRoutes />
      </Auth0ProviderWithRedirectCallback>
    </Router>
  );
}

export default App;