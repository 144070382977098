import React from 'react';
import { Row, Col, Spinner, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CourseCard from 'components/student-dashboard/Card';
import useFetchAllCourses from 'hooks/useFetchAllCourses';

const CourseList = ({ searchQuery = "" }) => {
    const { t } = useTranslation();
    const { allCourses, loading } = useFetchAllCourses();

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <Spinner animation="border" role="status" style={{ width: "3rem", height: "3rem" }}>
                    <span className="visually-hidden">{t('loading')}</span>
                </Spinner>
            </div>
        );
    }

    const filteredCourses = allCourses.filter(course =>
        course.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Container className="my-5">
            <h2 className="mb-4">{t('all_courses')}</h2>
            <Row className="g-4">
                {filteredCourses.map((course) => (
                    <Col lg={4} md={6} sm={12} key={course.id}>
                        <CourseCard course={course} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default CourseList;