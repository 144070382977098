import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CourseCard from 'components/student-dashboard/Card';
import useFetchCoursesNotInProgress from 'hooks/useFetchCoursesNotInProgress';

const CourseNotInProgressList = ({ userId, searchQuery = "" }) => {
    const { t } = useTranslation();
    const coursesNotInProgress = useFetchCoursesNotInProgress(userId);
    const filteredCourses = coursesNotInProgress.filter(course =>
        course.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    if (filteredCourses.length === 0) {
        return null;
    }

    return (
        <Container className="my-5">
            <h2 className="mb-4">{t('courses')}</h2>
            <Row className="g-4">
                {filteredCourses.map((course) => (
                    <Col lg={4} md={6} sm={12} key={course.id}>
                        <CourseCard course={course} />
                    </Col>
                ))}
            </Row>
        </Container>
    );
};

export default CourseNotInProgressList;