import { useState, useEffect } from 'react';
import useFetchAllCourses from 'hooks/useFetchAllCourses';
import useFetchUserCoursesInProgress from 'hooks/useFetchUserCoursesInProgress';

const useFetchCoursesNotInProgress = (userId) => {
    const { allCourses } = useFetchAllCourses();
    const { coursesInProgress } = useFetchUserCoursesInProgress(userId, allCourses);
    const [coursesNotInProgress, setCoursesNotInProgress] = useState([]);

    useEffect(() => {
        if (!allCourses || !coursesInProgress) return;

        const inProgressCourseIds = new Set(coursesInProgress.map(course => course.id));
        const filteredCourses = allCourses.filter(course => !inProgressCourseIds.has(course.id));

        setCoursesNotInProgress(filteredCourses);
    }, [allCourses, coursesInProgress]);

    return coursesNotInProgress;
};

export default useFetchCoursesNotInProgress;