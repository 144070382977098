import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useAuthTokens from './useAuthTokens';

const useLessonProgress = (courseId, userId) => {
  const { t } = useTranslation();
  const [lessonIdToNavigate, setLessonIdToNavigate] = useState(null);
  const [buttonText, setButtonText] = useState(t('start_learning'));
  const { accessToken } = useAuthTokens();

  useEffect(() => {
    const fetchLessonsAndProgress = async () => {
      try {
        const lessonsResponse = await fetch(`${process.env.REACT_APP_CONTENT_API_BASE_URL}/courses/${courseId}/lessons`);
        const lessonsData = await lessonsResponse.json();
        const lessonsArray = lessonsData.sections?.flatMap(section => section.lessons) || [];

        if (lessonsArray.length === 0) {
          console.warn('No lessons found for this course.');
          return;
        }

        if (!userId) {
          setLessonIdToNavigate(lessonsArray[0]?.id || null);
          setButtonText(t('start_learning'));
          return;
        }

        const progressResponse = await fetch(
          `${process.env.REACT_APP_API_BASE_URL}/users/api/v1/users/${userId}/courses/${courseId}`,
          {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${accessToken}`,
            },
          }
        );

        if (progressResponse.status === 404) {
          setLessonIdToNavigate(lessonsArray[0]?.id || null);
          setButtonText(t('start_learning'));
          return;
        }

        const progressData = await progressResponse.json();
        const completedLessonIds = new Set(progressData.completedLessons || []);

        const firstUncompletedLesson = lessonsArray.find(
          lesson => lesson.id && !completedLessonIds.has(lesson.id)
        );

        if (firstUncompletedLesson) {
          setLessonIdToNavigate(firstUncompletedLesson.id);
          setButtonText(t('continue_learning'));
        } else {
          setLessonIdToNavigate(lessonsArray[0]?.id || null);
          setButtonText(t('start_learning'));
        }
      } catch (error) {
        console.error('Error fetching lessons or course progress:', error);
      }
    };

    fetchLessonsAndProgress();
  }, [courseId, userId, t]);

  return { lessonIdToNavigate, buttonText };
};

export default useLessonProgress;