import { useLocation } from 'react-router-dom';
import { Card, Form, Row, Col, Button} from 'react-bootstrap';

import ProfileLayout from 'pages/StudentPage';

const EditProfile = () => {
	const pathInfo = useLocation();
	const account = pathInfo.pathname.substring(21, 11);
	return (
		<ProfileLayout>
			{account === 'instructor'}
			<Card className="border-0">
				<Card.Header>
					<div className="mb-3 mb-lg-0">
						<h3 className="mb-0">Personal Details</h3>
						<p className="mb-0">
                            Edit your personal information.
						</p>
					</div>
				</Card.Header>
				<Card.Body>
					<div>
						<Form>
							<Row>
								<Col md={6} sm={12} className="mb-3">
									<Form.Group className="mb-3" controlId="formFirstName">
										<Form.Label>First Name</Form.Label>
										<Form.Control
											type="text"
											placeholder="First Name"
											required
										/>
									</Form.Group>
								</Col>

								<Col md={6} sm={12} className="mb-3">
									<Form.Group className="mb-3" controlId="formLastName">
										<Form.Label>Last Name</Form.Label>
										<Form.Control
											type="text"
											placeholder="Last Name"
											required
										/>
									</Form.Group>
								</Col>

								<Col sm={12} md={12}>
									<Button variant="primary" type="submit">
										Update Profile
									</Button>
								</Col>
							</Row>
						</Form>
					</div>
				</Card.Body>
			</Card>
		</ProfileLayout>
	);
};

export default EditProfile;
