import React from 'react';
import { Row, Col, Spinner, Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import CourseCard from 'components/student-dashboard/ProgressCourseCard';
import useFetchAllCourses from 'hooks/useFetchAllCourses';
import useFetchUserCoursesInProgress from 'hooks/useFetchUserCoursesInProgress';

const CourseListInProgress = ({ userId, showprogressbar, searchQuery = "" }) => {
    const { t } = useTranslation();
    const { allCourses, loading: allCoursesLoading } = useFetchAllCourses();
    const { coursesInProgress, loading: inProgressLoading } = useFetchUserCoursesInProgress(userId, allCourses);

    const loading = allCoursesLoading || inProgressLoading;

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
                <Spinner animation="border" role="status" style={{ width: "3rem", height: "3rem" }}>
                    <span className="visually-hidden">{t('loading')}</span>
                </Spinner>
            </div>
        );
    }

    const coursesToShow = showprogressbar ? coursesInProgress : allCourses;

    const filteredCourses = coursesToShow.filter(course =>
        course.title.toLowerCase().includes(searchQuery.toLowerCase())
    );

    return (
        <Container className="my-5">
            {showprogressbar && coursesInProgress && coursesInProgress.length > 0 && (
                <h2 className="mb-4">{t('courses_in_progress')}</h2>
            )}
            <Row className="g-4">
                {filteredCourses.map((course) => (
                    <Col lg={4} md={6} sm={12} key={course.id}>
                        <CourseCard course={course} showprogressbar={showprogressbar} />
                    </Col>
                ))}
            </Row>
        </Container>    
    );
};

export default CourseListInProgress;