import { useState, useEffect } from 'react';
import useAuthTokens from 'hooks/useAuthTokens';

const useFetchUserCoursesInProgress = (userId, allCourses) => {
    const [coursesInProgress, setCoursesInProgress] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { accessToken } = useAuthTokens();

    const fetchUserCoursesInProgress = async () => {
        if (!userId || allCourses.length === 0) return;

        try {
            setLoading(true);
            setError(null);

            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/api/v1/users/${userId}/courses`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                },
            });

            if (!response.ok) throw new Error('Failed to fetch user courses');

            const userCoursesData = await response.json();
            if (userCoursesData === null) return [];

            const userCourseIds = userCoursesData.map(course => course.courseId);
            const userCourses = allCourses.filter(course => userCourseIds.includes(course.id));

            const coursesWithProgress = await Promise.all(
                userCourses.map(async (course) => {
                    const progressResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/api/v1/users/${userId}/courses/${course.id}`, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${accessToken}`,
                        },
                    });
                    const progressData = await progressResponse.json();

                    const lessonsResponse = await fetch(`${process.env.REACT_APP_CONTENT_API_BASE_URL}/courses/${course.id}/lessons`);
                    const lessonsData = await lessonsResponse.json();

                    const contentLessonIds = lessonsData.sections.flatMap(section => section.lessons.map(lesson => lesson.id));
                    const validCompletedLessons = progressData.completedLessons.filter(lessonId => contentLessonIds.includes(lessonId));

                    const progressPercentage = (validCompletedLessons.length / contentLessonIds.length) * 100;
                    return { ...course, progress: progressPercentage };
                })
            );

            setCoursesInProgress(coursesWithProgress.filter(Boolean));
        } catch (error) {
            console.error(error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUserCoursesInProgress();
    }, [userId, allCourses]);

    return { coursesInProgress, loading, error };
};

export default useFetchUserCoursesInProgress;