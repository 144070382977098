const parseResponse = (response) => {

  if (!response.sections) {
    console.error('Response does not contain sections');
    return [];
  }

  const CourseIndex = response.sections.map((section, index) => {
    return {
      id: index + 1,
      title: section.title, 
      content: section.content,
      topics: section.lessons.map(lesson => {
        return {
          id: lesson.id,
          topic: lesson.title,
          locked: lesson.open !== true 
        };
      })
    };
  });

  return CourseIndex;
};

export default parseResponse;