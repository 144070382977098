import React from 'react';
import { Button, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LessonNavigation = ({ prevLesson, nextLesson, isLessonCompleted, toggleCompletion, handlePrevLesson, handleNextLesson }) => {
  const { t } = useTranslation();

  return (
    <div className="d-flex justify-content-between mt-4 mb-4">
      {prevLesson ? (
        <Button variant="secondary" onClick={handlePrevLesson}>
          {t('previous_lesson')}
        </Button>
      ) : (
        <div></div>
      )}
      
      <div className="d-flex align-items-center">
        <Form.Check
          type="checkbox"
          label={t('lesson_completed')}
          checked={isLessonCompleted}
          onChange={(e) => toggleCompletion(e.target.checked)}
          className="me-4"
        />
        {nextLesson && (
          <Button variant="primary" onClick={handleNextLesson}>
            {t('next_lesson')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default LessonNavigation;