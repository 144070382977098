import { Row, Col, Card } from 'react-bootstrap';
import ProfileBackground from 'assets/images/background/profile-bg.jpg';

const ProfileCover = ({ user }) => {
    const emailInitial = user?.email ? user.email.charAt(0).toUpperCase() : 'U';

    return (
        <Row className="align-items-center">
            <Col xl={12} lg={12} md={12} sm={12}>
                <div
                    className="pt-16 rounded-top-md"
                    style={{
                        background: `url(${ProfileBackground})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover'
                    }}
                >
                </div>
                <Card className="px-4 pt-2 pb-4 rounded-0 rounded-bottom shadow-sm">
                    <div className="d-flex align-items-end justify-content-between">
                        <div className="d-flex align-items-center flex-wrap">
                            <div className="me-2 position-relative d-flex justify-content-end align-items-end mt-n5">
                                <div
                                    className="avatar-placeholder rounded-circle border border-2 border-white d-flex align-items-center justify-content-center"
                                    style={{
                                        width: '64px',
                                        height: '64px',
                                        backgroundColor: '#6c757d', 
                                        color: 'white',
                                        fontSize: '24px',
                                        fontWeight: 'bold'
                                    }}
                                >
                                    {emailInitial}
                                </div>
                            </div>
                            <div className="lh-1">
                                <h2 className="mb-0 text-truncate display-md-2 display-6">
                                    {user?.email || ''}
                                </h2>
                            </div>
                        </div>
                    </div>
                </Card>
            </Col>
        </Row>
    );
};

export default ProfileCover;